import Countdown from 'react-countdown';

import bomaluno from './bomaluno.svg';
import './App.css';

const App = () => {
  const targetDate = new Date('September 10, 2025').getTime();

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <div className="countdownWrap">
          <div className="countdownCol">
            <div className="countdownColValue">{days}</div>
            <div className="countdownColLabel">dias</div>
          </div>
          <div className="countdownCol">
            <div className="countdownColValue">{hours}</div>
            <div className="countdownColLabel">horas</div>
          </div>
          <div className="countdownCol">
            <div className="countdownColValue">{minutes}</div>
            <div className="countdownColLabel">minutos</div>
          </div>
          <div className="countdownCol">
            <div className="countdownColValue">{seconds}</div>
            <div className="countdownColLabel">segundos</div>
          </div>
        </div>
      );
    }
  };

  const statement = `Cansado de métodos de estudo que não funcionam?

Nós também.
`

  return (
    <div className="App">
      <header className="App-header">
        <div className="statement">
          {statement}
        </div>

         <Countdown
           date={targetDate}
           renderer={renderer}
        />
        <img src={bomaluno} className="App-logo" alt="logo" />
      </header>
    </div>
  );
};

export default App;
